@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

#cssmenu {
    #menu-button {
        display: none;
    }
    &.align-center {
        > ul {
            font-size: 0;
            text-align: center;
        }
        ul ul {
            text-align: left;
        }
        > ul > li {
            display: inline-block;
            float: none;
        }
    }
    &.align-right {
        > ul > li {
            float: right;
        }
        ul ul {
            text-align: right;
        }
    }
    > ul > li > a {
        -webkit-transition: color .2s ease;
        -moz-transition: color .2s ease;
        -ms-transition: color .2s ease;
        -o-transition: color .2s ease;
        transition: color .2s ease;
    }
    ul {
        > li.has-sub:hover > a {
            &:after {
                background-position: 0 -18px;
            }
            &:before {
                border-top-color: #19799f;
            }
        }
        ul {
            left: -9999px;
            opacity: 0;
            -webkit-transition: top .2s ease, opacity .2s ease;
            -moz-transition: top .2s ease, opacity .2s ease;
            -ms-transition: top .2s ease, opacity .2s ease;
            -o-transition: top .2s ease, opacity .2s ease;
            transition: top .2s ease, opacity .2s ease;
        }
    }
    > ul > li {
        > ul {
            top: 100px;
        }
        &:hover > ul {
            left: auto;
            top: 50px;
            opacity: 1;
        }
    }
    &.align-right > ul > li:hover > ul {
        right: 0;
    }
    ul ul {
        ul {
            top: 40px;
        }
        > li:hover > ul {
            top: 0;
            left: 178px;
            padding-left: 10px;
            opacity: 1;
        }
    }
    &.align-right ul ul > li:hover > ul {
        left: auto;
        right: 178px;
        padding-left: 0;
        padding-right: 10px;
        opacity: 1;
    }
    ul ul {
        li a {
            width: 180px;
            -webkit-transition: color .2s ease;
            -moz-transition: color .2s ease;
            -ms-transition: color .2s ease;
            -o-transition: color .2s ease;
            transition: color .2s ease;
        }
        > li.has-sub > a:after {
            right: 12px;
            top: 9.5px;
            background: #3db2e1;
            background: -webkit-linear-gradient(top, #58bde5 0%, #4ab7e3 25%, #2babde 50%, #58bde5 75%, #4ab7e3 100%);
            background: -ms-linear-gradient(top, #58bde5 0%, #4ab7e3 25%, #2babde 50%, #58bde5 75%, #4ab7e3 100%);
            background: -moz-linear-gradient(top, #58bde5 0%, #4ab7e3 25%, #2babde 50%, #58bde5 75%, #4ab7e3 100%);
            background: -o-linear-gradient(top, #58bde5 0%, #4ab7e3 25%, #2babde 50%, #58bde5 75%, #4ab7e3 100%);
            background: linear-gradient(to bottom, #58bde5 0%, #4ab7e3 25%, #2babde 50%, #58bde5 75%, #4ab7e3 100%);
            box-shadow: inset 0 -1px 1px #209ed0, inset 0 2px 1px #7fcceb;
            background-size: 36px 36px;
            background-position: 0 0;
            background-repeat: no-repeat;
        }
    }
    &.align-right ul ul > li.has-sub > a:after {
        right: auto;
        left: 12px;
    }
    ul ul > li.has-sub {
        &:hover > a:after {
            background-position: 0 -18px;
        }
        > a:before {
            top: 15.5px;
            right: 16px;
            border-top-color: transparent;
            border-left-color: #ffffff;
        }
    }
    &.align-right ul ul > li.has-sub > a:before {
        top: 15.5px;
        right: auto;
        left: 16px;
        border-top-color: transparent;
        border-right-color: #ffffff;
        border-left-color: transparent;
    }
    ul ul > li.has-sub:hover > a:before {
        border-top-color: transparent;
        border-left-color: #1c89b5;
    }
    &.align-right ul ul > li.has-sub:hover > a:before {
        border-top-color: transparent;
        border-left-color: transparent;
        border-right-color: #1c89b5;
    }
}

/*#cssmenu {
  width: auto;
  border-radius: 5px;
  font-family: 'Open Sans', Helvetica, sans-serif;
  background: #3db2e1;
  background: -o-linear-gradient(top, #69c4e8, #21a1d4);
  background: -ms-linear-gradient(top, #69c4e8, #21a1d4);
  background: -webkit-linear-gradient(top, #69c4e8, #21a1d4);
  background: -moz-linear-gradient(top, #69c4e8, #21a1d4);
  background: linear-gradient(to bottom, #69c4e8, #21a1d4);
  box-shadow: inset 0 -3px 0 #1f97c7, inset 0 -3px 3px #1f9acc, inset 0 2px 2px #9ad7ef, inset 1px 0 2px #22a4d9, inset -1px 0 2px #22a4d9, 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.06), 0 3px 3px rgba(0, 0, 0, 0.17), 2px 1px 2px rgba(0, 0, 0, 0.05), -2px 1px 2px rgba(0, 0, 0, 0.05);
}*/

@media all and (max-width: 768px), only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 1024px), only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 1024px), only screen and (-o-min-device-pixel-ratio: 2 / 1) and (max-width: 1024px), only screen and (min-device-pixel-ratio: 2) and (max-width: 1024px), only screen and (min-resolution: 192dpi) and (max-width: 1024px), only screen and (min-resolution: 2dppx) and (max-width: 1024px) {
    /*#cssmenu {
      width: 100%;
    }
    #cssmenu ul,
    #cssmenu ul ul,
    #cssmenu ul ul ul,
    #cssmenu > ul,
    #cssmenu.align-center > ul,
    #cssmenu > ul > li > ul,
    #cssmenu > ul > li:hover > ul,
    #cssmenu ul ul li:hover > ul,
    #cssmenu ul ul ul li:hover > ul,
    #cssmenu.align-right ul ul,
    #cssmenu.align-right ul ul li:hover > ul,
    #cssmenu.align-right ul ul ul li:hover > ul {
      position: relative;
      left: 0;
      right: auto;
      top: 0;
      width: 100%;
      display: none;
      padding: 0;
      opacity: 1;
      text-align: left;
    }*/
    /*#cssmenu ul li {
      width: 100%;
      border-top: 1px solid rgba(120, 120, 120, 0.2);
    }*/
    #cssmenu {
        > ul > li > a {
            width: 100%;
            border-radius: 0;
            box-shadow: none;
            background: none;
        }
        ul {
            ul li {
                a, &:first-child > a, &:last-child > a {
                    width: 100%;
                    border-radius: 0;
                    box-shadow: none;
                    background: none;
                }
            }
            li a {
                padding-left: 12.5px;
            }
            ul {
                li a {
                    padding: 14px 25px 14px 27.5px;
                }
                ul {
                    li a {
                        padding-left: 42.5px;
                    }
                    ul li a {
                        padding-left: 57.5px;
                    }
                }
                li.has-sub > a {
                    &:after, &:before {
                        display: none;
                    }
                }
            }
        }
        > ul > li.has-sub > a {
            &:after, &:before {
                display: none;
            }
        }
        #menu-button, .submenu-button {
            display: none;
        }
        ul {
            ul .submenu-button {
                height: 41px;
            }
            .submenu-button:after {
                content: '';
                position: absolute;
                right: 12.5px;
                top: 12.5px;
                display: block;
                width: 28px;
                height: 28px;
                border-radius: 15px;
                background: #3db2e1;
                background: -webkit-linear-gradient(top, #58bde5 0%, #4ab7e3 25%, #2babde 50%, #58bde5 75%, #4ab7e3 100%);
                background: -ms-linear-gradient(top, #58bde5 0%, #4ab7e3 25%, #2babde 50%, #58bde5 75%, #4ab7e3 100%);
                background: -moz-linear-gradient(top, #58bde5 0%, #4ab7e3 25%, #2babde 50%, #58bde5 75%, #4ab7e3 100%);
                background: -o-linear-gradient(top, #58bde5 0%, #4ab7e3 25%, #2babde 50%, #58bde5 75%, #4ab7e3 100%);
                background: linear-gradient(to bottom, #58bde5 0%, #4ab7e3 25%, #2babde 50%, #58bde5 75%, #4ab7e3 100%);
                box-shadow: inset 0 -1px 1px #209ed0, inset 0 2px 1px #7fcceb;
                background-size: 56px 56px;
                background-position: 0 0;
                background-repeat: no-repeat;
                -webkit-transition: all 0.1s ease-out;
                -moz-transition: all 0.1s ease-out;
                -ms-transition: all 0.1s ease-out;
                -o-transition: all 0.1s ease-out;
                transition: all 0.1s ease-out;
            }
        }
        #menu-button:after {
            content: '';
            position: absolute;
            right: 12.5px;
            top: 12.5px;
            display: block;
            width: 28px;
            height: 28px;
            border-radius: 15px;
            background: #3db2e1;
            background: -webkit-linear-gradient(top, #58bde5 0%, #4ab7e3 25%, #2babde 50%, #58bde5 75%, #4ab7e3 100%);
            background: -ms-linear-gradient(top, #58bde5 0%, #4ab7e3 25%, #2babde 50%, #58bde5 75%, #4ab7e3 100%);
            background: -moz-linear-gradient(top, #58bde5 0%, #4ab7e3 25%, #2babde 50%, #58bde5 75%, #4ab7e3 100%);
            background: -o-linear-gradient(top, #58bde5 0%, #4ab7e3 25%, #2babde 50%, #58bde5 75%, #4ab7e3 100%);
            background: linear-gradient(to bottom, #58bde5 0%, #4ab7e3 25%, #2babde 50%, #58bde5 75%, #4ab7e3 100%);
            box-shadow: inset 0 -1px 1px #209ed0, inset 0 2px 1px #7fcceb;
            background-size: 56px 56px;
            background-position: 0 0;
            background-repeat: no-repeat;
            -webkit-transition: all 0.1s ease-out;
            -moz-transition: all 0.1s ease-out;
            -ms-transition: all 0.1s ease-out;
            -o-transition: all 0.1s ease-out;
            transition: all 0.1s ease-out;
        }
        ul .submenu-button.submenu-opened:after, #menu-button.menu-opened:after {
            background-position: 0 -28px;
        }
        ul ul .submenu-button:after {
            top: 6.5px;
        }
        #menu-button:before, .submenu-button:before {
            content: '';
            position: absolute;
            right: 22.5px;
            top: 25.5px;
            display: block;
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-top-color: #ffffff;
            z-index: 99;
        }
        ul ul .submenu-button:before {
            top: 19.5px;
        }
        #menu-button.menu-opened:before, .submenu-button.submenu-opened:before {
            border-top-color: #19799f;
        }
    }
}